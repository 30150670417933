<template>
  <form @submit.prevent="submit" id="create-counterparty">
    <div class="row">
      <div class="col-md-12">
        <div class="form-group">
          <label>Name</label>
          <input
            type="text"
            v-model="counter_party.name"
            class="form-control"
            required
            placeholder="Name"
            name="name"
          />
        </div>
      </div>
      <div class="col-md-12">
        <div class="form-group">
          <label>Category</label>
          <select
            v-model="counter_party.category"
            class="form-control"
            required
            name="category"
          >
            <option value="counties">Counties</option
            ><option value="ministries">Ministries</option
            ><option value="parastatals">Parastatals</option
            ><option value="private_company">Private Company</option
            ><option value="ngo">NGO</option
            ><option value="universities">Universities</option
            ><option value="banks">Banks</option>
          </select>
        </div>
      </div>
      <div class="col-md-12">
        <div class="form-group">
          <label>Physical Address</label>
          <input
            name="physical_address"
            type="text"
            v-model="counter_party.physical_address"
            class="form-control"
            required
            placeholder="Physical Address"
          />
        </div>
      </div>
      <div class="col-md-12">
        <div class="form-group">
          <label>Postal Address</label>
          <input
            name="postal_address"
            type="text"
            v-model="counter_party.postal_address"
            class="form-control"
            required
            placeholder="Postal Address"
          />
        </div>
      </div>
      <div class="col-md-6">
        <router-link
          :to="{ name: 'counterparties.index' }"
          class="btn btn-warning"
          >Back</router-link
        >
      </div>
      <div class="col-md-6 text-right">
        <button class="btn btn-secondary">Save</button>
      </div>
    </div>
  </form>
</template>

<script>
export default {
  mounted() {
    this.fetch();
  },

  data() {
    return {
      counter_party: this.counterparty,
    };
  },

  props: {
    counterparty: {
      default() {
        return {}
      }
    }
  },

  methods: {
    submit() {
      this.$loader.start();
      this.$axios
        .put(
          `/api/v1/dashboard/counterparties/${this.counterparty.id}`,
          this.counter_party
        )
        .then(() => {
          this.$notify({
            group: "foo",
            title: "Success",
            text: "The counterparty has been updated",
            type: "success",
          });
          this.$loader.stop();
          this.$emit('close')
          this.$emit('added')
        })
        .catch((error) => {
          this.$http(error.response, "create-counterparty");
          this.$loader.stop();
        });
    },
  },
};
</script>
